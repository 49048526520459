export default [
    {
        path: '/',
        name: 'V2MainPage',
        component: function () { return import('../../../views/v2/MainView.vue')},
        redirect: to => { return { name: 'V2MyOrdersPage'} }
    },
    /*{
      path: '/old_main',
      name: 'V2MainPage',
      component: function () { return import('../../../views/v2/MainView.vue')},
      //redirect: to => { return { name: 'V2MyOrdersPage'} }
    },*/
    {
      path: '/yml_feed',
      name: 'V2YmlFeed',
      component: function () { return import('../../../views/v2/V2YmlFeed.vue')}
    },
    {
      path: '/sitemapxml',
      name: 'V2SitemapXML',
      component: function () { return import('../../../views/v2/V2SitemapXML.vue')}
    },
    {
      path: '/after-login',
      name: 'V2AfterLogin',
      component: function () { return import('../../../views/v2/V2AfterLogin.vue')}
    },
    {
      path: '/sitemap',
      name: 'V2Sitemap',
      component: function () { return import('../../../views/v2/V2Sitemap.vue')}
    },
    {
      path: '/instructions',
      name: 'V2Instructions',
      component: function () { return import('../../../views/v2/V2Instructions.vue')}
    },
    {
      path: '/:offset/:limit',
      name: 'V2MainPropsPage',
      props: true,
      component: function () { return import('../../../views/v2/MainView.vue')}
    },
    {
        path: 'login',
        name: 'V2LoginPage',
        component: function () { return import('../../../views/v2/Login.vue')}
    },
    {
      path: 'signup',
      name: 'Signup',
      component: function () { return import('../../../views/v2/Signup.vue')}
    },
    {
      path: 'restore',
      name: 'Restore',
      component: function () { return import('../../../views/v2/Restore.vue')}
    },
    {
      path: 'restore_password/:hash/:email',
      name: 'RestorePassword',
      props: true,
      component: function () { return import('../../../views/v2/RestorePassword.vue')}
    },
    {
      path: 'set_password/:code/:email',
      name: 'SignupSetPassword',
      props: true,
      component: function () { return import('../../../views/v2/SignupSetPassword.vue')}
    },
    {
      path: 'product/:id',
      name: 'V2ProductPage',
      props: true,
      component: function () { return import('../../../views/v2/V2ProductPage.vue')}
    },
    {
      path: 'my_products',
      name: 'V2MyProductsPage',
      props: true,
      component: function () { return import('../../../views/v2/V2MyProductsPage.vue')}
    },
    {
      path: 'my_products/:offset/:limit',
      name: 'V2MyProductsPropsPage',
      props: true,
      component: function () { return import('../../../views/v2/V2MyProductsPage.vue')}
    },
    {
      path: 'add_product',
      name: 'V2AddProductPage',
      props: true,
      component: function () { return import('../../../views/v2/V2AddProductPage.vue')}
    },
    {
      path: 'notifications',
      name: 'V2Notifications',
      props: true,
      component: function () { return import('../../../views/v2/V2Notifications.vue')}
    },
    {
      path: 'add_ref_product',
      name: 'V2AddRefProductPage',
      props: true,
      component: function () { return import('../../../views/v2/V2AddRefProductPage.vue')}
    },
    {
      path: 'import_products',
      name: 'V2ImportProductPage',
      props: true,
      component: function () { return import('../../../views/v2/V2ImportProductPage.vue')}
    },
    {
      path: 'edit_tovar/:id',
      name: 'V2EditTovarPage',
      props: true,
      component: function () { return import('../../../views/v2/V2EditTovarPage.vue')}
    },
    {
      path: 'my_orders',
      name: 'V2MyOrdersPage',
      props: true,
      component: function () { return import('../../../views/v2/V2MyOrdersPage.vue')}
    },
    {
      path: 'my_sales',
      name: 'V2MySalesPage',
      props: true,
      component: function () { return import('../../../views/v2/V2MySalesPage.vue')}
    },
    {
      path: 'favorites',
      name: 'V2MyFavorites',
      props: true,
      component: function () { return import('../../../views/v2/V2MyFavorites.vue')}
    },
    {
      path: 'my_account',
      name: 'V2MyAccount',
      props: true,
      component: function () { return import('../../../views/v2/V2MyAccount.vue')},
      children: [
        {
          path: 'common',
          name: 'V2MyAccountCommon',
          props: true,
          component: function () { return import('../../../views/v2/V2MyAccountCommon.vue')}
        },
        {
          path: 'password',
          name: 'V2MyAccountPassword',
          props: true,
          component: function () { return import('../../../views/v2/V2MyAccountPassword.vue')}
        },
        {
          path: 'company',
          name: 'V2MyAccountCompany',
          props: true,
          component: function () { return import('../../../views/v2/V2MyAccountCompany.vue')}
        },
      ]
    },
    { 
      path: "/:pathMatch(.*)*", 
      component:  function () { return import('../../../views/v2/404NotFound.vue') }
    }
]