import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import PrimeVue from "primevue/config";

createApp(App)
    .use(store)
    .use(router)
    .use(VueAxios, axios)
    .mount('#app')


axios.interceptors.response.use((response) => {
    //store.commit('SET_BLOCKED', 0)
    return response;
  },
  function(error) {
    if (error.response.status === 401) {
        console.log('unauthorized, logging out ...');
        store.commit('SET_ACCESS_BLOCKED_MESSAGE', false)
        console.log('SET_BLOCKED')
        store.commit('SET_BLOCKED', 1)
        console.log('SET_BLOCKED OK')
        localStorage.setItem('username', error.response.data.username)
    }
    if(error.response.status != 401) {
      store.commit('SET_SUCCESS', {state: true, text: error.response.data.data, is_error: true})
    }
    if(error.response.status == 403) {
      console.log('error data interceptor')
      console.log(error)
      store.commit('SET_SUCCESS', {state: true, text: error.response.data.error, is_error: true})
    }
    return Promise.reject(error.response);
  }
);