import axios from "axios"
export default {
    state: {
        company: {
            // инн андромеды 7731430259
            id: 0,
            inn: '',
            full_name: '',
            ur_adres: '',
            kpp: '',
            bank_name: '',
            schet: '',
            bik: '',
            kor_schet: '',
            phones: '',
            emails: '',
            manager: '',
            okpo: '',
            ogrn: '',
            okved: '',
            oktmo: '',
            createdAt: '',
            updatedAt: '',
            owner_id: 0,
            //fio_contact_person: ''
            //fio_contact_person: ''
        },
        company_old: {
            // инн андромеды 7731430259
            id: 0,
            inn: '',
            full_name: '',
            ur_adres: '',
            kpp: '',
            bank_name: '',
            schet: '',
            bik: '',
            kor_schet: '',
            phones: '',
            emails: '',
            manager: '',
            okpo: '',
            ogrn: '',
            okved: '',
            oktmo: '',
            createdAt: '',
            updatedAt: '',
            owner_id: 0,
            //fio_contact_person: ''
            //fio_contact_person: ''
        },
        profile: {
            password: '',
            confirm_password: ''
        }
    },
    getters: {
        company: (state) =>state.company,
        company_old: (state) =>state.company_old,
        profile: (state) =>state.profile,
    },
    mutations: {
        SET_COMPANY_FIELD(state, playload) {
            state.company[playload.field] = playload.value
        },
        SET_PROFILE_FIELD(state, playload) {
            state.profile[playload.field] = playload.value
        },
        SET_COMPANY(state, playload) {
            state.company = playload
            state.company_old.id = playload.id
            state.company_old.owner_id = playload.owner_id
            state.company_old.inn = playload.inn
            state.company_old.full_name = playload.full_name
            state.company_old.ur_adres = playload.ur_adres
            state.company_old.kpp = playload.kpp
            state.company_old.bank_name = playload.bank_name
            state.company_old.schet = playload.schet
            state.company_old.bik = playload.bik
            state.company_old.kor_schet = playload.kor_schet
            state.company_old.phones = playload.phones
            state.company_old.emails = playload.emails
            state.company_old.manager = playload.manager
            state.company_old.okpo = playload.okpo
            state.company_old.ogrn = playload.ogrn
            state.company_old.okved = playload.okved
            state.company_old.oktmo = playload.oktmo
            state.company_old.createdAt = playload.createdAt
            state.company_old.updatedAt = playload.updatedAt
            state.company_old.fio_contact_person = playload.fio_contact_person
        }
    },
    actions: {
        setCompanyField({commit}, data) {
            commit('SET_COMPANY_FIELD', data)
        },
        setProfileField({commit}, data) {
            commit('SET_PROFILE_FIELD', data)
        },
        async searchDataByInn({commit, state}) {
            commit('SET_PRELOADER', true)
            var url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/party";
            var token = "709f86bbecbbcc8c794dcb848f2439833981e49d";
            var query = state.company.inn;

            var options = {
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": "Token " + token
                },
                body: JSON.stringify({query: query})
            }

            await fetch(url, options)
            .then(response => response.json())
            .then(resault => {
                console.log(resault.suggestions[0].data)
                commit('SET_COMPANY_FIELD', {field: 'full_name', value: resault.suggestions[0].data.name.full_with_opf})
                commit('SET_COMPANY_FIELD', {field: 'ur_adres', value: resault.suggestions[0].data.address.unrestricted_value})
                commit('SET_COMPANY_FIELD', {field: 'kpp', value: resault.suggestions[0].data.kpp})
                commit('SET_COMPANY_FIELD', {field: 'phones', value: resault.suggestions[0].data.phones})
                commit('SET_COMPANY_FIELD', {field: 'emails', value: resault.suggestions[0].data.emails})
                commit('SET_COMPANY_FIELD', {field: 'manager', value: resault.suggestions[0].data.management.name})
                commit('SET_COMPANY_FIELD', {field: 'okpo', value: resault.suggestions[0].data.okpo})
                commit('SET_COMPANY_FIELD', {field: 'ogrn', value: resault.suggestions[0].data.ogrn})
                commit('SET_COMPANY_FIELD', {field: 'okved', value: resault.suggestions[0].data.okved})
                commit('SET_COMPANY_FIELD', {field: 'oktmo', value: resault.suggestions[0].data.oktmo})
                commit('SET_PRELOADER', false)
            })
            .catch(error => {
                console.log("error", error)
                commit('SET_PRELOADER', false)
            });
        },
        async getCompanyData({commit}) {
            commit('SET_PRELOADER', true)
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')
            await axios.get(`${process.env.VUE_APP_API_URL}/api/profile/company`)
                .then(response => {
                    if(response.data.data != null)
                        commit('SET_COMPANY', response.data.data)
                    commit('SET_PRELOADER', false)
                })
                .catch(error => { 
                    console.log(error)
                    //commit('SET_ERROR', {field: 'state', value: true})
                    //commit('SET_ERROR', {field: 'text', value: error.response.data.data})
                    commit('SET_PRELOADER', false)
                })
        },
        async updateCompany({dispatch, state, commit}, role) {
            // проверяем поля на заполнение
            /* if(role == 'ROLE_SALLER')
                for(let item in state.company) {
                    //console.log(item)
                    //console.log(state.company[item])
                    if(item != 'createdAt' && item != 'updatedAt' && item != 'fio_contact_person' && state.company[item].length == 0) {
                        console.log('Здесь пусто ' + item)
                        commit('SET_ERROR', {field: 'state', value: true})
                        commit('SET_ERROR', {field: 'text', value: 'Все поля обязательны для заполнения'})
                        return
                    }
                } */

            // проверяем поля
            if(role == 'ROLE_SALLER') {
                if(state.company['inn'].length == 0 || state.company['full_name'].length == 0 || state.company['ur_adres'].length == 0 || state.company['phones'].length == 0 || state.company['emails'].length == 0) {
                    commit('SET_ERROR', {field: 'state', value: true})
                    commit('SET_ERROR', {field: 'text', value: 'Все поля обязательны для заполнения'})
                    return
                }
            }

            if(role == 'ROLE_CUSTOMER') {
                if(state.company['inn'].length == 0 || state.company['full_name'].length == 0 || state.company['ur_adres'].length == 0) {
                    commit('SET_ERROR', {field: 'state', value: true})
                    commit('SET_ERROR', {field: 'text', value: 'Все поля обязательны для заполнения'})
                    return
                }
            }
            
            commit('SET_PRELOADER', true)
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')
            await axios.post(`${process.env.VUE_APP_API_URL}/api/profile/update_company`, state.company)
                .then(response => {
                    dispatch('getCompanyData')
                    dispatch('getUserData')
                    commit('SET_PRELOADER', false)
                })
                .catch(error => { 
                    console.log(error)
                    //commit('SET_ERROR', {field: 'state', value: true})
                    //commit('SET_ERROR', {field: 'text', value: error.response.data.data})
                    commit('SET_PRELOADER', false)
                })
        },
        async updatePassword({state, commit}) {
            commit('SET_PRELOADER', true)
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')
            await axios.post(`${process.env.VUE_APP_API_URL}/api/profile/update_password`, state.profile)
                .then(response => {
                    //commit('SET_COMPANY', response.data.data)
                    commit('SET_PRELOADER', false)
                })
                .catch(error => { 
                    console.log(error)
                    //commit('SET_ERROR', {field: 'state', value: true})
                    //commit('SET_ERROR', {field: 'text', value: error.response.data.data})
                    commit('SET_PRELOADER', false)
                })
        },


    }
}