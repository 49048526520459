import axios from "axios"
export default {
    state: {

        export_tovars: {
            filename: '',
            is_active: false
        },

        ref_tovar: {
            id: 0,
            name: '',
            article: '',
            description: '',
            registration_certificate: '',
            photo_url: '',
        },
        ref_tovar_owner: false,
        tovars: {},
        ref_tovars: {},
        tovars_count: 0,
        tovars_orders: {},
        offset: 0,
        limit: 12,
        active_order: false,
        simmilar_tovars: [],
        tovar_photos: {},
        tovar: {
            id: 0,
            refbook_tovar_id: 0,
            company_id: 0,
            name: '',
            article: '',
            place_id: 0,
            expiration_date: '',
            date_of_issue: '',
            quantity_in_stock: 0,
            quantity_type: 1,
            quantity_in_transit: 0,
            quantity_in_transit_type: 1,
            date_of_delivery: '',
            description: '',
            registration_certificate: '',
            photo_url: '',
            owner_id: '',
            expiration_date_val: '',
            date_of_issue_val: '',
            date_of_delivery_val: '',
            is_tovar_by_thing: 0,
            is_tovar_by_box: 0,
            count_in_pack: 0,
            measurement_type: '',
            city: {
                name: '',
                region: {
                    name: ''
                }
            },
            ref_tovar: {
                photo_url: '',
                article: '',
                name: '',
                description: ''
            },
        },
        refbook_cities: {},
        citi_search: '',
        tovar_timer: '',
        tovar_order_count: 0,
        filters: {
            name: '',
            place: 0,
            place_name: '',
            date: '',
            status: -1
        },
        quantity_type_types: [
            {
                id: 0,
                name: 'Не указано',
                value: 'Не указано'
            },
            {
                id: 1,
                name: 'Штук (шт.)',
                value: 'штук'
            },
            {
                id: 2,
                name: 'Упаковок (упаковок)',
                value: 'упаковок'
            },
        ],
        tovar_order_history: {},
        import_resaults: {},
        measurement_types: [
            {
                id: 1,
                name: 'Штука',
                value: 'Шт.'
            },
            {
                id: 2,
                name: 'Упаковка',
                value: 'Упак.'
            },
            {
                id: 3,
                name: 'Метр',
                value: 'Метр'
            },
            {
                id: 4,
                name: 'Пара',
                value: 'Пара'
            },
        ]
    },
    getters: {
        ref_tovar: (state) => state.ref_tovar,
        ref_tovar_owner: (state) => state.ref_tovar_owner,
        tovar: (state) => state.tovar,
        tovars: (state) => state.tovars,
        tovar_photos: (state) => state.tovar_photos,
        tovars_count: (state) => state.tovars_count,
        ref_tovars: (state) => state.ref_tovars,
        tovar_id: (state) => state.tovar.id,
        refbook_cities: (state) => state.refbook_cities,
        citi_search: (state) => state.citi_search,
        filters: (state) => state.filters,
        offset: (state) => state.offset,
        limit: (state) => state.limit,
        quantity_type_types: (state) => state.quantity_type_types,
        active_order: (state) => state.active_order,
        tovars_orders: (state) => state.tovars_orders,
        tovar_order_history: (state) => state.tovar_order_history,
        import_resaults: (state) => state.import_resaults,
        measurement_types: (state) => state.measurement_types,
        tovar_order_count: (state) => state.tovar_order_count,
        export_tovars: (state) => state.export_tovars,
        simmilar_tovars: (state) => state.simmilar_tovars
    },
    mutations: {
        SET_SIMMILAR_TOVARS(state, playload) {
            state.simmilar_tovars = playload
        },
        SET_EXPORT_TOVARS(state, playload) {
            state.export_tovars = playload
        },
        SET_TOVAR_ORDER_COUNT(state, playload) {
            state.tovar_order_count = playload
        },
        CLEAR_REF_TOVARS(state) {
            state.ref_tovars = {}
        },
        SET_IMPORT_RESAULT(state, playload) {
            state.import_resaults = playload
        },
        SET_TOVAR_ORDER_HISTORY(state, playload) {
            state.tovar_order_history = playload
        },
        SET_REF_TOVAR(state, playload) {
            state.ref_tovar = playload
        },
        SET_REF_TOVAR_OWNER(state, playload) {
            state.ref_tovar_owner = playload
        },
        SET_REF_TOVAR_FIELD(state, playload) {
            state.ref_tovar[playload.field] = playload.value
        },
        SET_TOVAR(state, playload) {
            state.tovar = playload
        },
        SET_TOVAR_PHOTOS(state, playload) {
            state.tovar_photos = playload
        },
        SET_REFBOOK_CITIES(state, playload) {
            state.refbook_cities = playload
        },
        SET_CITY_SEARCH(state, playload) {
            state.citi_search = playload
        },
        SET_TOVAR_FIELD(state, playload) {
            state.tovar[playload.field] = playload.value
        },
        SET_TOVARS(state, playload) {
            state.tovars = playload
        },
        SET_REF_TOVARS(state, playload) {
            state.ref_tovars = playload
        },
        SET_FILTER_FIELD(state, playload) {
            state.filters[playload.field] = playload.value
        },
        SET_TOVARS_COUNT(state, playload) {
            state.tovars_count = playload
        },
        SET_OFFSET(state, playload) {
            state.offset = playload
        },
        SET_LIMIT(state, playload) {
            state.limit = playload
        },
        SET_ACTIVE_ORDER(state, playload) {
            state.active_order = playload
        },
        SET_TOVARS_ORDERS(state, playload) {
            state.tovars_orders = playload
        }
    },
    actions: {
        setOffset({commit}, data) {
            commit('SET_OFFSET', data)
        },
        setFilterField({commit}, data) {
            commit('SET_FILTER_FIELD', data)
        },
        setTovarField({commit}, data) {
            commit('SET_TOVAR_FIELD', data)
        },
        setCitySearch({commit, dispatch, state}, data) {
            commit('SET_CITY_SEARCH', data.value)
        },
        setRefTovarField({commit}, data) {
            commit('SET_REF_TOVAR_FIELD', data)
        },
        setTovarStatus({commit, dispatch}, data) {
            commit('SET_PRELOADER', true)
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')
            axios.post(`${process.env.VUE_APP_API_URL}/api/tovars/tovar`, {
                tovar_id: data.tovar_id,
                status_id: data.status_id
            })
                .then(response => { 
                    dispatch('getMyTovars')
                    commit('SET_PRELOADER', false)
                })
                .catch(error => { 
                    commit('SET_PRELOADER', false)
                    console.log(error) 
                    //commit('SET_ERROR', {field: 'state', value: true})
                    //commit('SET_ERROR', {field: 'text', value: error.response.data.data})
                })
        },
        deleteTovar({commit, dispatch}, data) {
            commit('SET_PRELOADER', true)
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')
            axios.delete(`${process.env.VUE_APP_API_URL}/api/tovars/tovar/`+data.tovar_id)
                .then(response => { 
                    dispatch('getMyTovars')
                    commit('SET_PRELOADER', false)
                })
                .catch(error => { 
                    commit('SET_PRELOADER', false)
                    console.log(error) 
                    //commit('SET_ERROR', {field: 'state', value: true})
                    //commit('SET_ERROR', {field: 'text', value: error.response.data.data})
                })
        },
        createRefTovar({commit, state}) {
            commit('SET_PRELOADER', true)
            let formData = new FormData();
            //let filesarray = []
            //filesarray.push(state.ref_tovar.registration_certificate)
            //filesarray.push(state.ref_tovar.photo_url)
            //formData.append('files', filesarray)

            formData.append('file', state.ref_tovar.registration_certificate)
            formData.append('photo', state.ref_tovar.photo_url)
            formData.append('name', state.ref_tovar.name)
            formData.append('article', state.ref_tovar.article)
            formData.append('description', state.ref_tovar.description)
            

            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')
            axios.post(`${process.env.VUE_APP_API_URL}/api/tovars/create_new_tovar`, formData)
                .then(response => { 
                    commit('SET_PRELOADER', false)
                    if(response.data.tovar_data.city == null) 
                        response.data.tovar_data.city = {
                            name: 'Выбрать местоположение',
                            region: {
                                name: ''
                            }
                        }
                    commit('SET_TOVAR', response.data.tovar_data)
                })
                .catch(error => { 
                    commit('SET_PRELOADER', false)
                    console.log(error) 
                    //commit('SET_ERROR', {field: 'state', value: true})
                    //commit('SET_ERROR', {field: 'text', value: error.response.data.data})
                })
        },
        getRefTovar({commit}, id) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')
            axios.get(`${process.env.VUE_APP_API_URL}/api/tovars/ref_tovar/` + id)
                .then(response => { 
                    commit('SET_PRELOADER', false)
                    commit('SET_REF_TOVAR', response.data.data)
                })
                .catch(error => { 
                    commit('SET_PRELOADER', false)
                    console.log(error) 
                })
        }, 
        updateRefTovar({commit, state, dispatch}) {
            commit('SET_PRELOADER', true)
            let formData = new FormData();
            formData.append('id', state.ref_tovar.id)
            formData.append('name', state.ref_tovar.name)
            formData.append('article', state.ref_tovar.article)
            formData.append('description', state.ref_tovar.description)
            

            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')
            axios.patch(`${process.env.VUE_APP_API_URL}/api/tovars/update_ref_tovar`, formData)
                .then(response => { 
                    commit('SET_PRELOADER', false)
                    commit('SET_SUCCESS', {
                        state: true,
                        text: 'Обновление успешно'
                    })
                    dispatch('getRefTovar', state.ref_tovar.id)
                    dispatch('getTovar', state.tovar.id)
                })
                .catch(error => { 
                    commit('SET_PRELOADER', false)
                    console.log(error) 
                })
        },
        updateRefTovarPhoto({commit, state, dispatch}) {
            commit('SET_PRELOADER', true)
            let formData = new FormData();
            formData.append('id', state.ref_tovar.id)
            formData.append('photo', state.ref_tovar.photo_url)
            //formData.append('article', state.ref_tovar.article)
            //formData.append('description', state.ref_tovar.description)
            

            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')
            axios.post(`${process.env.VUE_APP_API_URL}/api/tovars/update_ref_tovar_photo`, formData)
                .then(response => { 
                    commit('SET_PRELOADER', false)
                    commit('SET_SUCCESS', {
                        state: true,
                        text: 'Фото успешно обновлено'
                    })
                    dispatch('getRefTovar', state.ref_tovar.id)
                })
                .catch(error => { 
                    commit('SET_PRELOADER', false)
                    console.log(error) 
                })
        },
        updateRefTovarRegCert({commit, state, dispatch}) {
            commit('SET_PRELOADER', true)
            let formData = new FormData();
            formData.append('id', state.ref_tovar.id)
            formData.append('file', state.ref_tovar.registration_certificate)

            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')
            axios.post(`${process.env.VUE_APP_API_URL}/api/tovars/update_ref_tovar_registration_certificate`, formData)
                .then(response => { 
                    commit('SET_PRELOADER', false)
                    commit('SET_SUCCESS', {
                        state: true,
                        text: 'РУ успешно обновлено'
                    })
                    dispatch('getRefTovar', state.ref_tovar.id)
                })
                .catch(error => { 
                    commit('SET_PRELOADER', false)
                    console.log(error) 
                })
        },
        async getTovar({commit}, tovar_id) {
            commit('SET_PRELOADER', true)
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')
            await axios.get(`${process.env.VUE_APP_API_URL}/api/tovars/tovar/`+tovar_id)
                .then(response => { 
                    if(response.data.data.city == null)
                        response.data.data.city = {
                            name: 'Выбрать местоположение',
                            region: {
                                name: ''
                            }
                        }
                    commit('SET_PRELOADER', false)
                    commit('SET_TOVAR', response.data.data)
                    commit('SET_TOVAR_PHOTOS', response.data.photos)
                    commit('SET_REF_TOVAR', response.data.ref_tovar)
                    commit('SET_REF_TOVAR_OWNER', response.data.is_owner)
                    commit('SET_ACTIVE_ORDER', response.data.active_order)
                })
                .catch(error => { 
                    commit('SET_PRELOADER', false)
                    console.log(error) 
                    //commit('SET_ERROR', {field: 'state', value: true})
                    //commit('SET_ERROR', {field: 'text', value: error.response.data.data})
                })
        },
        async getMPTovar({commit}, tovar_id) {
            commit('SET_PRELOADER', true)
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')
            await axios.get(`${process.env.VUE_APP_API_URL}/api/mainpage/tovar/`+tovar_id)
                .then(response => { 
                    if(response.data.data.city == null)
                        response.data.data.city = {
                            name: 'Выбрать местоположение',
                            region: {
                                name: ''
                            }
                        }
                    commit('SET_PRELOADER', false)
                    commit('SET_TOVAR', response.data.data)
                    commit('SET_REF_TOVAR', response.data.ref_tovar)
                    commit('SET_TOVAR_ORDER_COUNT', response.data.count_orders)
                    commit('SET_SIMMILAR_TOVARS', response.data.simmilar_tovars)
                    //commit('SET_REF_TOVAR_OWNER', response.data.is_owner)
                    //commit('SET_ACTIVE_ORDER', response.data.active_order)
                })
                .catch(error => { 
                    commit('SET_PRELOADER', false)
                    console.log(error) 
                    //commit('SET_ERROR', {field: 'state', value: true})
                    //commit('SET_ERROR', {field: 'text', value: error.response.data.data})
                })
        },
        getRefbookCities({commit, state}) {
            commit('SET_PRELOADER', true)
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')
            axios.post(`${process.env.VUE_APP_API_URL}/api/tovars/refbook_cities`, {
                name: state.citi_search
            })
                .then(response => { 
                    commit('SET_REFBOOK_CITIES', response.data.data)
                    commit('SET_PRELOADER', false)
                })
                .catch(error => { 
                    commit('SET_PRELOADER', false)
                    console.log(error) 
                    //commit('SET_ERROR', {field: 'state', value: true})
                    //('SET_ERROR', {field: 'text', value: error.response.data.data})
                })
        },
        updateTovarField({dispatch, state, commit}, data) {
            data.id = state.tovar.id
            commit('SET_PRELOADER', true)
            // проверяем чтобы не было пустого значения для количества
            if((data.field == 'quantity_in_stock')&&(data.value.length == 0))
                data.value = 0
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')
            axios.patch(`${process.env.VUE_APP_API_URL}/api/tovars/tovar`, 
                data
            )
                .then(response => { 
                    dispatch('getTovar', state.tovar.id)
                    commit('SET_PRELOADER', false)
                })
                .catch(error => { 
                    commit('SET_PRELOADER', false)
                    console.log(error) 
                    //commit('SET_ERROR', {field: 'state', value: true})
                    //commit('SET_ERROR', {field: 'text', value: error.response.data.data})
                })
        },
        getMyTovars({commit, state}) {
            commit('SET_PRELOADER', true)
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')
            axios.post(`${process.env.VUE_APP_API_URL}/api/tovars/my_tovars`, {
                name: state.filters.name,
                place: state.filters.place,
                date: state.filters.date,
                offset: state.offset*state.limit,
                limit: state.limit,
                status: state.filters.status
            })
                .then(response => { 
                    commit('SET_TOVARS', response.data.data)
                    commit('SET_TOVARS_COUNT', response.data.count)
                    commit('SET_TOVARS_ORDERS', response.data.orders_array)
                    commit('SET_PRELOADER', false)
                })
                .catch(error => { 
                    commit('SET_PRELOADER', false)
                    console.log(error) 
                    //commit('SET_ERROR', {field: 'state', value: true})
                    //commit('SET_ERROR', {field: 'text', value: error.response.data.data})
                })
        },
        exportMyTovars({commit, state}) {
            commit('SET_PRELOADER', true)
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')
            axios.get(`${process.env.VUE_APP_API_URL}/api/tovars/export_my_tovars`)
                .then(response => { 
                    //commit('SET_TOVARS', response.data.data)
                    //commit('SET_TOVARS_COUNT', response.data.count)
                    //commit('SET_TOVARS_ORDERS', response.data.orders_array)
                    commit('SET_EXPORT_TOVARS', {
                        filename: response.data.file,
                        is_active: true
                    })
                    commit('SET_PRELOADER', false)
                })
                .catch(error => { 
                    commit('SET_PRELOADER', false)
                    console.log(error) 
                    //commit('SET_ERROR', {field: 'state', value: true})
                    //commit('SET_ERROR', {field: 'text', value: error.response.data.data})
                })
        },
        getRefTovars({commit, state}, name) {
            commit('SET_PRELOADER', true)
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')
            axios.post(`${process.env.VUE_APP_API_URL}/api/tovars/ref_tovars`, {
                name: name
            })
                .then(response => { 
                    commit('SET_REF_TOVARS', response.data.data)
                    commit('SET_PRELOADER', false)
                })
                .catch(error => { 
                    commit('SET_PRELOADER', false)
                    console.log(error) 
                    //commit('SET_ERROR', {field: 'state', value: true})
                    //commit('SET_ERROR', {field: 'text', value: error.response.data.data})
                })
        },
        async createNewTovar({commit, state}, id) {
            commit('SET_PRELOADER', true)
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')
            await axios.post(`${process.env.VUE_APP_API_URL}/api/tovars/create_from_ref`, {
                ref_tovar_id: id
            })
                .then(response => { 
                    if(response.data.tovar_data.city == null) 
                        response.data.tovar_data.city = {
                            name: 'Выбрать местоположение',
                            region: {
                                name: ''
                            }
                        }
                    commit('SET_TOVAR', response.data.tovar_data)
                    commit('SET_PRELOADER', false)
                })
                .catch(error => { 
                    commit('SET_PRELOADER', false)
                    console.log(error) 
                    //commit('SET_ERROR', {field: 'state', value: true})
                    //commit('SET_ERROR', {field: 'text', value: error.response.data.data})
                })
        },
        Tovar__OrderHistory({commit}, tovar_id) {
            commit('SET_PRELOADER', true)
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')
            axios.get(`${process.env.VUE_APP_API_URL}/api/tovars/tovar/history/`+tovar_id)
                .then(response => { 
                    commit('SET_TOVAR_ORDER_HISTORY', response.data.data)
                    commit('SET_PRELOADER', false)
                })
                .catch(error => { 
                    commit('SET_PRELOADER', false)
                    console.log(error) 
                    //commit('SET_ERROR', {field: 'state', value: true})
                    //commit('SET_ERROR', {field: 'text', value: error.response.data.data})
                })
        },
        async Tovar_AddInWaitingList({commit}, tovar_id) {
            commit('SET_PRELOADER', true)
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')
            await axios.post(`${process.env.VUE_APP_API_URL}/api/tovars/tovar/in_waiting_list`, {
                tovar_id: tovar_id,
            })
                .then(response => { 
                    commit('SET_PRELOADER', false)
                    commit('SET_SUCCESS', {state: true, text: 'Вы добавлены в лист ожидания товара'})
                })
                .catch(error => { 
                    commit('SET_PRELOADER', false)
                    console.log(error) 
                    //commit('SET_SUCCESS', {state: true, text: error.response.data.data, is_error: true})
                })
        },
        async Tovar__SaveAndPublic({commit, dispatch}, tovar_id) {
            commit('SET_PRELOADER', true)
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')
            await axios.get(`${process.env.VUE_APP_API_URL}/api/tovars/tovar/save_public/`+tovar_id)
                .then(response => { 
                    commit('SET_PRELOADER', false)
                    commit('SET_SUCCESS', {state: true, text: 'Товар успешно опубликова'})
                    dispatch('getTovar', tovar_id)
                })
                .catch(error => { 
                    commit('SET_PRELOADER', false)
                    console.log(error) 
                    //commit('SET_SUCCESS', {state: true, text: error.response.data.data, is_error: true})
                })
        },
        async Tovar__UnPublic({commit, dispatch}, tovar_id) {
            commit('SET_PRELOADER', true)
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')
            await axios.get(`${process.env.VUE_APP_API_URL}/api/tovars/tovar/unpublic/`+tovar_id)
                .then(response => { 
                    commit('SET_PRELOADER', false)
                    commit('SET_SUCCESS', {state: true, text: 'Товар снят с публикации'})
                    dispatch('getTovar', tovar_id)
                })
                .catch(error => { 
                    commit('SET_PRELOADER', false)
                    console.log(error) 
                    //commit('SET_SUCCESS', {state: true, text: error.response.data.data, is_error: true})
                })
        },
        async Tovar__ImportTovarsFromExcel({commit}, files) {
                commit('SET_PRELOADER', true)
                let formData = new FormData();
                formData.append('file', files.file)
                formData.append('file_photos', files.file_photos)
                formData.append('file_ru', files.file_ru)
    
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')
                await axios.post(`${process.env.VUE_APP_API_URL}/api/tovars/tovars/import`, formData)
                    .then(Response => { 
                        commit('SET_PRELOADER', false)
                        commit('SET_SUCCESS', {state: true, text: 'Импорт успешно выполнен'})
                        commit('SET_IMPORT_RESAULT', Response.data.data)
                    })
                    .catch(error => { 
                        commit('SET_PRELOADER', false)
                        console.log(error) 
                        //commit('SET_SUCCESS', {state: true, text: error.response.data.data, is_error: true})
                     })
        },
        async GENERETE_RefTovarArticle({commit}) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')
            await axios.get(`${process.env.VUE_APP_API_URL}/api/tovars/generate_article`)
                    .then(Response => { 
                        commit('SET_PRELOADER', false)
                        commit('SET_SUCCESS', {state: true, text: 'Каталожный номер успешно сгенерирован'})
                        commit('SET_REF_TOVAR_FIELD', {field: 'article', value: Response.data.data})
                    })
                    .catch(error => { 
                        commit('SET_PRELOADER', false)
                        console.log(error) 
                        //commit('SET_SUCCESS', {state: true, text: error.response.data.data, is_error: true})
                     })
        } 
    }
}